.section {
  background-color:#F5F8FA;
  padding-bottom: 3rem;
  padding-top: 7rem;
}

@media (min-width: 1023px) {
  .section {
    display: flex;
    background-color: white;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.section__content {
  flex: 0 0 100%;
  display: flex;
  align-items: flex-start;
  height: max-content;
  padding-bottom: 4.2rem;
  background-color:#F5F8FA;
}

@media (min-width: 1023px) {
  .section__content {
    height: 43.75rem;
    padding-bottom: 0;
    align-items: center;
  }
}

@media (min-width: 1280px) {
  .section__content {
    height: 50rem;
  }
}

@media (min-width: 1023px) {
  .section__body {
    max-width: 50%;
    padding-right: 1.25rem;
  }
}

@media (min-width: 1280px) {
  .section__body {
    max-width: 55%;
  }
}

.section__image {
  display: flex;
  justify-content: center;
}

@media (min-width: 1023px) {
  .section__image {
    display: block;
  } 
}

.section__image-box {
  max-width: 21.5625rem;
  height: 23.125rem;
  border-radius: 3rem;
  background-color: #082544;
}

@media (min-width: 1023px) {
  .section__image-box {
    display: flex;
    max-width: 100%;
    height: 46.25rem;
    border-radius: 0;
    border-bottom-left-radius: 3rem;
    background-color: #082544;
  }
}

@media (min-width: 1280px) {
  .section__image-box {
    height: 52.5rem;
  }
}

@media (min-width: 1023px) {
  .section__image {
    flex: 0 0 50%;
    transform: translate(-100%, 0);
  }
}

@media (min-width: 1280px) {
  .section__image {
    flex: 0 0 45%;
  }
}
