.split {
  background-color: #F5F8FA;
  padding-bottom: 4.375rem;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .split {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    overflow: hidden;
  }
}

@media (min-width: 1024px) {
  .split {
    background-color: white;
  }
}

.split__left {
  display: flex;
  justify-content: center;
  background-color: #082544;
  padding: 3.75rem 2rem;
  border-bottom-left-radius: 3rem;
  border-bottom-right-radius: 3rem;
}

@media (min-width: 1024px) {
  .split__left {
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 45%;
    height: 52.5rem;
    padding: 0 2rem 0 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
  }
}

@media (min-width: 1280px) {
  .split__left {
    padding: 0 8.5rem 0 0;
  }
}

@media (min-width: 1536px) {
  .split__left {
    padding: 0 10rem 0 0;
  }
}

.split__left-container {
  max-width: 27rem;
}

@media (min-width: 1536px) {
  .split__left-container {
    max-width: 32rem;
  }
}

.split__right {
  padding-left: 1rem;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .split__right {
    flex: 0 0 55%;
    height: 43.75rem;
    background-color: #F5F8FA;
  }
}
