@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Fauna+One&family=Poppins:wght@400;500;600;700&display=swap');

@tailwind base;
@import "tailwindcss/components";
@import "tailwindcss/utilities";


body {
  font-family: "Poppins";
}

path:focus {
  outline: none;
}

.sidebar {
  -webkit-animation: slide-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
